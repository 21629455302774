<template>
  <div class="program-checkin--form">
    <v-row v-if="isChallenge" class="d-flex" no-gutter outlined>
      <v-col class="d-flex align-center">
        <v-dialog
          ref="dialog"
          v-model="open"
          :return-value.sync="dates"
          :width="isChallenge ? 290 : 700"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="primary px-7" v-bind="attrs" v-on="on">
              Add New Check In
              <v-icon class="ml-4"> {{ icons.calendar }} </v-icon>
            </v-btn>
            <v-checkbox
              v-model="allow_extended_checkin"
              label="Extend End"
              color="primary"
              class="px-7"
            />
          </template>

          <v-date-picker
            v-model="dates"
            @change="sortDates"
            :show-current="pickerFace"
            :allowed-dates="allowedDates"
            scrollable
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="error"
              :disabled="form.$busy"
              @click="open = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="info"
              :loading="form.$busy"
              @click="addNewCheckin"
            >
              Add Check In
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-spacer />
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-btn class="primary px-7" @click="open = !open">
          Add New Check In
          <v-icon class="ml-4"> {{ icons.calendar }} </v-icon>
        </v-btn>
      </v-col>

      <v-col v-show="open" cols="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="form.week"
              :items="weeks"
              :error-messages="form.$getError('week')"
              :hide-details="!form.$getError('week')"
              item-text="name"
              item-value="id"
              label="Week"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="form.day_from"
              :items="days"
              :error-messages="form.$getError('day_from')"
              :hide-details="!form.$getError('day_from')"
              item-text="name"
              item-value="id"
              label="Day From"
              clearable
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="form.day_to"
              :items="days"
              :error-messages="form.$getError('day_to')"
              :hide-details="!form.$getError('day_to')"
              item-text="name"
              item-value="id"
              label="Day To"
              clearable
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="form.$busy"
              @click="open = false"
              color="error"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="form.$busy"
              @click="addNewCheckin"
              color="info"
              text
            >
              Add Check In
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Challenge from '@/models/Challenge'
import Program from '@/models/Program'
import utc from 'dayjs/plugin/utc'
import Form from '@/utils/form'
import dayjs from 'dayjs'

import { mapMutations, mapGetters } from 'vuex'
import { mdiPlus, mdiCalendar } from '@mdi/js'
import { map, extend } from 'lodash'
import { mask } from 'vue-the-mask'

dayjs.extend(utc)

export default {
  name: 'ProgramCheckinForm',

  props: {
    type: [Number, String],

    for: {
      type: [Program, Challenge],
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      open: false,

      dates: [],
      checkins: [],

      allow_extended_checkin: false,
      icons: {
        add: mdiPlus,
        calendar: mdiCalendar,
      },

      form: new Form({
        type: this.type,
        day_from: null,
        day_to: null,
        week: null,
        from: null,
        to: null,
      }),

      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      program: 'programs/getSelectedProgram',
      challenge: 'challenges/getSelectedChallenge',
    }),

    isChallenge() {
      return this.for instanceof Challenge
    },

    pickerFace() {
      return this.challenge.id
        ? this.challenge.starts_at
        : dayjs().format('YYYY-MM-DD')
    },

    hasDates() {
      return this.dates.length > 1
    },

    weekCount() {
      return this.isChallenge
        ? this.challenge.week_count
        : this.program.week_count
    },

    weeks() {
      return map([...Array(this.weekCount).keys()], (week) => {
        let _week = ++week

        return { id: _week, name: _week }
      })
    },
  },

  methods: {
    ...mapMutations({
      addProgramCheckin: 'programs/insertSelectedProgramCheckin',
      addChallengeCheckin: 'challenges/insertSelectedChallengeCheckin',
    }),

    sortDates(dates) {
      this.dates = dates.sort()
    },

    async addNewCheckin() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.form = extend(this.form, this.setDateFromTo())

      await this.for
        .checkins()
        .attach(this.form)
        .then(({ data }) => {
          if (data) {
            this.clearDates()

            this.form.$timeout(() => {
              this.form.$reset()
              this.form.$busy = false

              if (this.for instanceof Program) {
                this.addProgramCheckin({ ...data.data })
              }

              if (this.for instanceof Challenge) {
                this.addChallengeCheckin({ ...data.data })
              }

              this.$refs.dialog.save(this.dates)
            })
          }
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    setDateFromTo() {
      const from = this.dates[0]
        ? dayjs
            .utc(this.dates[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm')
        : null

      const to = this.dates[1]
        ? dayjs
            .utc(this.dates[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        : null

      return { from, to }
    },

    clearDates() {
      this.dates = []
    },

    allowedeDays(day, direction) {
      if (direction === 'left') {
        return this.form.day_from > day.id || false
      } else {
        return (!!this.form.day_to && this.form.day_to < day.id) || false
      }
    },

    allowedDates(date) {
      if (
        (!this.allow_extended_checkin &&
          this.challenge.starts_at <= date &&
          this.challenge.ends_at >= date) ||
        (this.allow_extended_checkin && this.challenge.starts_at <= date)
      ) {
        return true
      }

      return false
    },
  },

  watch: {
    open(value) {
      if (!value) {
        this.form.$reset()
      }
    },
  },
}
</script>
