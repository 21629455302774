<template>
  <div class="new-program-details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Program Check Ins</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            text
            depressed
            :loading="loading"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="mb-12">
        <program-checkin-form :for="program" :type="1" />

        <v-card v-if="hasCheckins" class="mt-7" outlined>
          <template v-for="(checkin, index) in checkins">
            <v-card-text :key="index">
              <div class="d-flex align-center justify-space-between">
                <div>
                  <div class="caption text--primary">
                    Checkin #{{ ++index }}
                  </div>
                  <div class="subtitle-1 primary--text">
                    <span class="mr-3"> Week {{ checkin.week }}: </span>
                    {{ checkin.dayFrom | title }} -
                    {{ checkin.dayTo | title }}
                  </div>
                </div>

                <div>
                  <v-btn
                    color="error"
                    class="ma-2"
                    @click="removeCheckin(checkin)"
                    text
                  >
                    Remove
                  </v-btn>
                </div>
              </div>
            </v-card-text>

            <v-divider
              v-if="index < checkins.length"
              :key="`divider-${index}`"
            />
          </template>
        </v-card>

        <no-list v-else details="No Checkin List" />
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ProgramCheckinForm from '@/components/forms/programs/ProgramCheckinForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'
import Program from '@/models/Program'

import { mapGetters, mapMutations } from 'vuex'
import { mdiArrowRight, mdiPlus } from '@mdi/js'

export default {
  name: 'ProgramCheckins',

  components: {
    NoList,
    ConfirmDialog,
    ProgramCheckinForm,
  },

  data() {
    return {
      loading: false,
      program: new Program({ id: this.$attrs.id }),
      icons: {
        next: mdiArrowRight,
        add: mdiPlus,
      },
    }
  },

  created() {
    this.fetchProgramCheckins()
  },

  computed: {
    ...mapGetters({
      checkins: 'programs/getSelectedProgramCheckins',
      isCompleted: 'programs/isSelectedProgramComplete',
    }),

    hasCheckins() {
      return !!this.checkins.length
    },
  },

  methods: {
    ...mapMutations({
      deleteCheckin: 'programs/deleteSelectedProgramCheckin',
      setProgramCheckins: 'programs/setSelectedProgramCheckins',
    }),

    async fetchProgramCheckins() {
      let { data } = await this.program.checkins().get()

      this.setProgramCheckins(data)
    },

    async removeCheckin(checkin) {
      const confirm = await this.$refs.confirmDelete.open(
        'Remove Checkin',
        'Are you sure you want to remove this checkin date?'
      )

      if (!confirm) return

      try {
        await checkin.for(this.program).delete()

        this.deleteCheckin(checkin)
      } catch (err) {
        return err
      }
    },

    saveForLater() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'completed.programs',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'draft.programs',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },

    saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'program.variants',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.program.variants',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
  },
}
</script>
